/**
 * The objective of this class is to help organize, filter and format the data that comes from the API.
 */

export class InsightManager {

    /**
     * 
     * @param data array of insights 
     * @param type type of insights to filter by, can be all to not filter by anything
     * @returns the insight with latest end_date on it.
     */
    static GetLatest(data: any, type: string = "all", fallback: string = "none") {
        if (!data || data.length === 0) {
            return null;
        }
    
        try {
            var filtered_data = FilterAndSort(data, type);
            if (filtered_data === null && fallback !== 'none') {
                filtered_data = FilterAndSort(data, fallback);
            }
    
            if (filtered_data && filtered_data?.length > 0) {
                var d: any = { ...filtered_data[filtered_data.length - 1] };
    
                if (typeof d.data === 'string') {
                    try {
                        const fixedJsonString = d.data.replace(/'/g, '"');
                        d.data = JSON.parse(fixedJsonString);
                    } catch (jsonError) {
                        console.error("Error parsing JSON:", jsonError);
                        d.data = null; 
                    }
                } else if (typeof d.data === 'object') {
                } else {
                    console.warn("Unexpected data format in GetLatest:", d.data);
                }
    
                return d;
            }
            return null;
        } catch (error) {
            console.error("Error in GetLatest:", error);
            return null;
        }
    }
    
    
    /**
     * 
     * @param data array of insights 
     * @param type type of insights to filter by, can be all to not filter by anything
     * @param cutoff number of insights to return
     * @returns an object with a list of labels with the dates of the insights and a list of values with the values of the insights
     */
    static GetLabelsAndValues(data: any, type: string = "all", cutoff: number): { labels: any, values: any } | null {

        // TODO implement the "all" type to not filter by anything
        if (data.length === 0) {
            return null;
        }
    
        // Filter data by type
        var filtered_data = FilterAndSort(data, type);
        if (filtered_data === null) {
            return null;
        }
    
        var metrics: any = {};
    
        // If the cutoff is smaller than the length of the data, we will only get the last "cutoff" elements
        if (filtered_data.length > cutoff) {
            filtered_data = filtered_data.slice(filtered_data.length - cutoff, filtered_data.length);
        }
    
        for (let i = 0; i < filtered_data.length; i++) {
            try {
                if (filtered_data[i].data.value) {
                    metrics[filtered_data[i].date_end.substring(5)] = filtered_data[i].data.value;
                    continue;
                } else if (typeof filtered_data[i].data === 'string') {
                    try {
                        const fixedJsonString = filtered_data[i].data.replace(/'/g, '"');
                        const details = JSON.parse(fixedJsonString);
                        metrics[filtered_data[i].date_end.substring(5)] = details.value;
                    } catch (jsonError) {
                        console.error("Error parsing JSON:", jsonError, filtered_data[i].data);
                    }
                } else if (typeof filtered_data[i].data === 'object') {
                    metrics[filtered_data[i].date_end.substring(5)] = filtered_data[i].data.value;
                } else {
                    console.warn("Unexpected data format:", filtered_data[i].data);
                }
            } catch (error) {
                console.error("Error parsing JSON from an insight", error, filtered_data[i].data);
            }
        }
    
        return metrics;
    }
    

    static GetBestMetric(insights: any[]) {
        const grouped = InsightManager.GroupInsightsByType(insights);

        // Result container to hold the greatest improvement
        let greatestImprovement: {
            insight: any | null;
            improvement: number;
            final_value: number;
            values: number[];
        } = { insight: null, final_value: 0, improvement: 0, values: [] };

        // Iterate over each insight_type group
        for (const [type, group] of Object.entries(grouped)) {
            // Sort the group by date_start (oldest to newest)
            group.sort((a, b) => new Date(a.date_start).getTime() - new Date(b.date_start).getTime());

            try {
                // Initialize tracking for improvements within this group
                let initialValue: number | null = JSON.parse(escapeJsonString(group[group.length - 2].data)).value;
                let finalValue: number | null = JSON.parse(escapeJsonString(group[group.length - 1].data)).value;
                const values = group.map((g: any) => JSON.parse(escapeJsonString(g.data)).value);

                // Calculate improvement percentage if we have initial and final values
                if (initialValue !== null && finalValue !== null && initialValue !== finalValue) {
                    const improvement = ((finalValue - initialValue) / initialValue) * 100;
                    // Check if this improvement is the greatest so far
                    if (improvement > greatestImprovement.improvement) {
                        const parsedData = JSON.parse(escapeJsonString(group[group.length - 1].data));
                        greatestImprovement = {
                            insight: group[group.length - 1],
                            improvement: improvement,
                            final_value: parsedData.value,
                            values: values
                        };
                    }
                }
            }
            catch (error) {
                console.error("Error calculating improvement:", error);
            }
        }

        if (greatestImprovement.insight) {
            try {
                greatestImprovement.insight.data = JSON.parse(escapeJsonString(greatestImprovement.insight.data));
            } catch (error) {
                console.error("Error parsing greatest improvement data:", error);
            }
        }

        return greatestImprovement;
    }

    static GroupInsightsByType = (insights: any[]) => {
        // Group by insight type
        const grouped: { [key: string]: any[] } = {};

        insights.map((insight) => {
            if (!grouped[insight.insight_type]) {
                grouped[insight.insight_type] = [];
            }
            grouped[insight.insight_type].push(insight);
        });

        return grouped;
    }
}

export default InsightManager;

/**
 * Escapes and transforms a JSON string to a valid JSON format
 * @param jsonString - the raw JSON string to be escaped
 */
function escapeJsonString(jsonString: any) {
    if (typeof jsonString !== 'string') {
        console.error("Non-string input received in escapeJsonString:", jsonString);
        return jsonString; // Return the input as-is if it's not a string
    }
    try {
        const escapedString = jsonString
            .replace(/'/g, '"')
            .replace(/(\b\w+\b)(?=:)/g, '"$1"');
        return escapedString;
    } catch (error) {
        console.error("Error escaping JSON string:", error);
        return jsonString; // Return the original string in case of error
    }
}

/**
 * Filters and sorts data by type and date_end
 * @param data - the array of insights
 * @param type - the type of insights to filter
 * @returns filtered and sorted data
 */
function FilterAndSort(data: any, type: string = "all") {
    var filtered_data = [...data.filter((d: any) => d.insight_type === type)];

    if (filtered_data.length === 0) {
        return null;
    }

    // Organize filtered data by the latest date
    filtered_data.sort((a: any, b: any) => {
        return new Date(a.date_end).getTime() - new Date(b.date_end).getTime();
    });

    return filtered_data;
}
