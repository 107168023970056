import React, { useContext, useState, useEffect } from "react";
import Header from "../components/header";
import SprintHistory from "../components/sprintHistory";
import Ring from "../components/ring";
import InsightTeam from "../components/insightTeam";
import BottomNavigation from "../components/BottomNavigation";
import LineChart from "../components/lineChart";
import { useNavigate, useLocation } from "react-router-dom";
import { DashboardContext } from "../context/DashboardContext";
import InsightManager from "../manager/insightManager";
import { sentiment_rgb, caring_rgb, engagement_rgb } from "../config";
import starColorImg from "../img/star-color.png";
import heartColorImg from "../img/heart-color.png";
import emojiColorImg from "../img/emoji_color_5.png";
import TimeFilter from "../components/timeFilter";

const MeVsTeam: React.FC = () => {
  const { sprints, project, filters, showFilter } = useContext(DashboardContext);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tokenFromUrl = queryParams.get("token");
  const tokenFromStorage = localStorage.getItem("token");
  const token = tokenFromUrl || tokenFromStorage;

  const [filteredInsights, setFilteredInsights] = useState<any[]>([]);
  const [lineChartData, setLineChartData] = useState<any[]>(
    location.state?.lineChartData || []
  );
  const { selectedSprint, setSelectedSprint } = useContext(DashboardContext);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get("token");
    const tokenFromStorage = localStorage.getItem("token");
    const token = tokenFromUrl || tokenFromStorage;

    if (!token) {
      navigate("/");
    } else {
      if (!tokenFromUrl && tokenFromStorage) {
        navigate(`${location.pathname}?token=${token}`, { replace: true });
      }
    }
  }, [navigate, location.search, location.pathname]);

  useEffect(() => {
    if (sprints.length > 0) {
      if (!selectedSprint) {
        const recentSprint = sprints[sprints.length - 1]; 
        setSelectedSprint(recentSprint);
      }

      const filtered = lineChartData.filter((insight: any) => {
        const start = new Date(insight.date_start);
        const end = new Date(insight.date_end);
        const sprintStart = new Date(selectedSprint?.start_date || sprints[sprints.length - 1].start_date);
        const sprintEnd = new Date(selectedSprint?.end_date || sprints[sprints.length - 1].end_date);
  
        return start >= sprintStart && end <= sprintEnd;
      });
  
      setFilteredInsights(filtered);
    }
  }, [sprints, lineChartData, selectedSprint, setSelectedSprint]);
  

  useEffect(() => {
    if (selectedSprint) {
      const filtered = lineChartData.filter((insight: any) => {
        const start = new Date(insight.date_start);
        const end = new Date(insight.date_end);
        const sprintStart = new Date(selectedSprint.start_date);
        const sprintEnd = new Date(selectedSprint.end_date);
  
        return start >= sprintStart && end <= sprintEnd;
      });
  
      setFilteredInsights(filtered);
    }
  }, [selectedSprint, lineChartData]);
  

  const sentimentIndividual =
    InsightManager.GetLatest(filteredInsights, "sentiment_individual")?.data
      ?.value || 0;
  const caringIndividual =
    InsightManager.GetLatest(filteredInsights, "caring_individual")?.data
      ?.value || 0;
  const engagementIndividual =
    InsightManager.GetLatest(filteredInsights, "engagement_individual")?.data
      ?.value || 0;

  const sentimentTeam = Math.round(
    InsightManager.GetLatest(filteredInsights, "sentiment_team")?.data?.value || 0
  );
  const caringTeam = Math.round(
    InsightManager.GetLatest(filteredInsights, "caring_team")?.data?.value || 0
  );
  const engagementTeam = Math.round(
    InsightManager.GetLatest(filteredInsights, "engagement_team")?.data?.value || 0
  );
      

  const generateInsightMessage = (
    type: string,
    individual: number,
    team: number
  ): string => {
    if (type === "engagement") {
      if (individual > team + 10) return "You're outperforming the team in engagement!";
      if (Math.abs(individual - team) <= 10) return "Your engagement is in line with your team.";
      return "Consider focusing on improving your engagement to match your team.";
    }
    if (type === "caring") {
      if (individual > team + 10) return "You're showing great care for the team!";
      if (Math.abs(individual - team) <= 10) return "Your caring score aligns with your team.";
      return "Try connecting more with your team to improve your caring score.";
    }
    if (type === "sentiment") {
      if (individual > team + 10) return "Your sentiment is outstanding! Keep spreading positivity.";
      if (Math.abs(individual - team) <= 10) return "Your sentiment is in line with your team.";
      return "Focus on improving your sentiment for a better team atmosphere.";
    }
    return "";
  };

  const teamInsightsMock = [
    {
      icon: starColorImg,
      message: generateInsightMessage(
        "engagement",
        engagementIndividual,
        engagementTeam
      ),
      color: "rgba(222, 122, 4, 0.3)",
      type: "engagement",
    },
    {
      icon: heartColorImg,
      message: generateInsightMessage("caring", caringIndividual, caringTeam),
      color: "rgba(135, 43, 147, 0.3)",
      type: "caring",
    },
    {
      icon: emojiColorImg,
      message: generateInsightMessage(
        "sentiment",
        sentimentIndividual,
        sentimentTeam
      ),
      color: "rgba(48, 180, 187, 0.3)",
      type: "sentiment",
    },
  ];

  const calculateOverall = (
    filters: string[],
    sentiment: number,
    caring: number,
    engagement: number
  ): number => {
    const values = [];
    if (filters.length === 0 || filters.includes("sentiment"))
      values.push(sentiment);
    if (filters.length === 0 || filters.includes("caring")) values.push(caring);
    if (filters.length === 0 || filters.includes("engagement"))
      values.push(engagement);
    if (values.length === 0) return 0;
    const total = values.reduce((acc, val) => acc + val, 0);
    return Math.round(total / values.length);
  };

  const overalIndividual = calculateOverall(
    filters,
    sentimentIndividual,
    caringIndividual,
    engagementIndividual
  );

  const overalTeam = calculateOverall(
    filters,
    sentimentTeam,
    caringTeam,
    engagementTeam
  );

  return (
    <div className="me-vs-team-page">
      <Header showBackground={true} />
  
      <div className="sprint-history-container">
        <SprintHistory
          sprints={sprints}
          onSelectSprint={(sprint) => {
            setSelectedSprint(sprint); 
            const filtered = lineChartData.filter((insight: any) => {
              const start = new Date(insight.date_start);
              const end = new Date(insight.date_end);
              const sprintStart = new Date(sprint.start_date);
              const sprintEnd = new Date(sprint.end_date);

              return start >= sprintStart && end <= sprintEnd;
            });
            setFilteredInsights(filtered); 
          }}
        />

      </div>
  
      {showFilter && <TimeFilter sprints={sprints} />}
  
      <div
        className="bg-white rounded-xl mt-6 w-full max-w-md"
        style={{ maxWidth: 360, marginTop: "30px", paddingTop: "12px" }}
      >
        <h4
          className="font-semibold"
          style={{
            fontFamily: "Graphik, sans-serif",
            fontSize: "14px",
            fontWeight: 600,
            color: "#53565A",
            lineHeight: "15.4px",
            marginBottom: "12px",
            padding: "24px",
          }}
        >
          Me and My Team
        </h4>
        <div className="flex flex-row justify-center space-x-16">
          {/* Individual Performance */}
          <div className="flex flex-col items-center">
            <div className="RingContainer w-[110px] h-[110px] flex flex-col items-center">
              <Ring
                color={sentiment_rgb}
                progress={sentimentIndividual}
                strokeWidth={2.8}
                size={40}
                scale={1.43}
                filter={"sentiment"}
                showCenterValue={filters.includes("sentiment")}
              />
              <Ring
                color={caring_rgb}
                progress={caringIndividual}
                strokeWidth={3.5}
                size={40}
                scale={1.1}
                filter={"caring"}
                showCenterValue={filters.includes("caring")}
              />
              <Ring
                color={engagement_rgb}
                progress={engagementIndividual}
                strokeWidth={5}
                size={40}
                scale={0.77}
                filter={"engagement"}
                showCenterValue={filters.includes("engagement")}
              />
              {(filters.length === 0 || filters.length > 1) && (
                <p className="text-xl absolute left-[50%] top-[50%] translate-x-[-40%] translate-y-[-50%] text-light-gray font-bold">
                  {overalIndividual}%
                </p>
              )}
            </div>
            <p className="text-center font-medium mt-5" style={{ color: "#92929D", fontSize: "12px" }}>
              You
            </p>
          </div>
  
          {/* Team Performance */}
          <div className="flex flex-col items-center">
            <div className="RingContainer w-[110px] h-[110px] flex flex-col items-center">
              <Ring
                color={sentiment_rgb}
                progress={sentimentTeam}
                strokeWidth={2.8}
                size={40}
                scale={1.43}
                filter={"sentiment"}
                showCenterValue={filters.includes("sentiment")}
              />
              <Ring
                color={caring_rgb}
                progress={caringTeam}
                strokeWidth={3.5}
                size={40}
                scale={1.1}
                filter={"caring"}
                showCenterValue={filters.includes("caring")}
              />
              <Ring
                color={engagement_rgb}
                progress={engagementTeam}
                strokeWidth={5}
                size={40}
                scale={0.77}
                filter={"engagement"}
                showCenterValue={filters.includes("engagement")}
              />
              {(filters.length === 0 || filters.length > 1) && (
                <p className="text-xl absolute left-[50%] top-[50%] translate-x-[-40%] translate-y-[-50%] text-light-gray font-bold">
                  {overalTeam}%
                </p>
              )}
            </div>
            <p className="text-center font-medium mt-5" style={{ color: "#92929D", fontSize: "12px" }}>
              {project?.name || "Team"}
            </p>
          </div>
        </div>
      </div>
  
      <InsightTeam insights={teamInsightsMock} title="SPRINT" filters={filters} />
  
      <div
        className="bg-white rounded-xl mt-6 p-6 w-full max-width-md"
        style={{ maxWidth: 360, marginBottom: "12px", marginTop:"12px"}}
      >
        <LineChart insights={lineChartData} showTeamMetrics={true} />
      </div>
  
      <BottomNavigation
        projectName={project?.name || "Project"}
        selectedTab={"Team"}
        onSelectTab={() => {
          navigate("/dashboard");
        }}
      />
    </div>
  );
};
export default MeVsTeam;
