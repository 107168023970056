import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { TokenProvider } from './context/TokenProvider';

import LandingPage from './routes/landing';
import DashboardHomePage from './routes/dashboard';
import AchievementsPage from './routes/achievementsPage';
import BadgeDetailsPage from './routes/badgeDetailsPage';
import MyBadgesPage from './routes/myBadgesPage';
import MeVsTeam from './routes/meVsTeam';
import QuickGuide from './routes/quickGuideViews/QuickGuide';
import AboutUs from './routes/aboutUs';
import ContactUs from './routes/contactUs';
import Menu from './routes/menu';

const mainRouter = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/dashboard",
    element: (
      <TokenProvider>
        <DashboardHomePage />
      </TokenProvider>
    ),
    children: [
      {
        path: "allbadges",
        element: <AchievementsPage />,
      },
      {
        path: "badgedetails",
        element: <BadgeDetailsPage />,
      },
      {
        path: "mybadges",
        element: <MyBadgesPage />,
      },
      {
        path: "meVsTeam",
        element: <MeVsTeam />,
      },
    ],
  },
  {
    path: "/dashboard/menu",
    element: <Menu />,
  },
  {
    path: "quick-guide",
    element: <QuickGuide />,
  },
  {
    path: "about-us",
    element: <AboutUs />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={mainRouter} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
