import React from "react";
import styles from "../styles/components/CounterBadges.module.css";

type CounterBadgesProps = {
  totalBadges: number;
  engagementCount: number;
  caringCount: number;
  sentimentCount: number;
};

const icons = {
  engagement: require("../img/star-gray.png"),
  caring: require("../img/heart-gray.png"),
  sentiment: require("../img/emoji_gray_5.png"),
};

const CounterBadges: React.FC<CounterBadgesProps> = ({
  totalBadges,
  engagementCount,
  caringCount,
  sentimentCount,
}) => {
  return (
    <div className={styles.container}>
      <div className="text-center">
        <h2 className={styles.title}>
          You've earned <br /> {totalBadges} Badges
        </h2>
        <div className={styles["badge-container"]}>
          {/* Engagement Badge */}
          <div className={styles.badge}>
            <div
              className={styles["badge-background"]}
              style={{ "--badge-color": "#DE7A04" } as React.CSSProperties}
            >
              <img
                src={icons.engagement}
                alt="Engagement icon"
                className={styles["badge-icon"]}
              />
              <span
                className={styles["badge-counter-circle"]}
                style={{ "--badge-border-color": "#DE7A04" } as React.CSSProperties}
              >
                {engagementCount}
              </span>
            </div>
            <span className={styles["badge-text"]}>Engagement</span>
          </div>

          {/* Caring Badge */}
          <div className={styles.badge}>
            <div
              className={styles["badge-background"]}
              style={{ "--badge-color": "#872B93" } as React.CSSProperties}
            >
              <img
                src={icons.caring}
                alt="Caring icon"
                className={styles["badge-icon"]}
              />
              <span
                className={styles["badge-counter-circle"]}
                style={{ "--badge-border-color": "#872B93" } as React.CSSProperties}
              >
                {caringCount}
              </span>
            </div>
            <span className={styles["badge-text"]}>Caring</span>
          </div>

          {/* Sentiment Badge */}
          <div className={styles.badge}>
            <div
              className={styles["badge-background"]}
              style={{ "--badge-color": "#30B4BC" } as React.CSSProperties}
            >
              <img
                src={icons.sentiment}
                alt="Sentiment icon"
                className={styles["badge-icon"]}
              />
              <span
                className={styles["badge-counter-circle"]}
                style={{ "--badge-border-color": "#30B4BC" } as React.CSSProperties}
              >
                {sentimentCount}
              </span>
            </div>
            <span className={styles["badge-text"]}>Sentiment</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterBadges;
