import React, { useState, useEffect, useContext } from "react";
import { useSearchParams, useNavigate, Outlet, useLocation } from "react-router-dom";
import { sentiment_rgb, engagement_rgb, caring_rgb } from "../config";
import { DashboardContext } from "../context/DashboardContext";
import { TeamfloAPI } from "../manager/apimanager";
import { useToken } from "../context/TokenProvider";

import Header from "../components/header";
import SprintHistory from "../components/sprintHistory";
import RingChart from "../components/ringChart";
import Insight from "../components/insight";
import LineChart from "../components/lineChart";
import TimeFilter from "../components/timeFilter";
import BestMetrics from "../components/BestMetrics";
import Badges from "../components/Badges_V2";
import NewBadgesPopup from "../components/NewBadgesPopup";
import BottomNavigation from "../components/BottomNavigation";

import InsightManager from "../manager/insightManager";
import MiniLineChart from "../components/miniLineChart";

const DashboardHomePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { token, setToken } = useToken();

  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("User");

  const [projects, setProjects] = useState<any[]>([]);
  const [project, setProject] = useState<any>();
  const [insights, setInsights] = useState<any[]>([]);
  const [filteredInsights, setFilteredInsights] = useState<any[]>([]);
  const [lineChartData, setLineChartData] = useState<any[]>([]);
  const [sprints, setSprints] = useState<any[]>([]);
  const [selectedSprint, setSelectedSprint] = useState<any>();
  const [badges, setBadges] = useState<any[]>([]);
  const [filters, setFilters] = useState<string[]>([]);

  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [displayedSprints, setDisplayedSprints] = useState<any[]>([]);

  const isDetailPage =
    location.pathname === "/dashboard/badgedetails" ||
    location.pathname === "/dashboard/mybadges" ||
    location.pathname === "/dashboard/allbadges";

  const isMeVsTeamView = location.pathname === "/dashboard/meVsTeam";

  useEffect(() => {
    if (!searchParams.has("token") && token) {
      const newUrl = `${location.pathname}?token=${token}`;
      navigate(newUrl, { replace: true });
    }
  }, [searchParams, token, navigate, location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      if (!token && searchParams.has("token")) {
        const newToken = searchParams.get("token") as string;
        setToken(newToken);
        localStorage.setItem("token", newToken);
  
        const result = await GetData(newToken);
        if (result) {
          console.log("Data fetched successfully:", result);
        }
      } else if (!token) {
        const storedToken = localStorage.getItem("token");
        if (storedToken) {
          setToken(storedToken);
          await GetData(storedToken);
        } else {
          navigate("/"); 
        }
      }
    };
    fetchData();
  }, []); 
  
  useEffect(() => {
    if (project) {
      LoadProjectDetails();
    }
  }, [project]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
    if (selectedSprint) {
      const updatedFilteredInsights = insights.filter((insight) => {
        const start = new Date(insight.date_start);
        const end = new Date(insight.date_end);
        const sprintStart = new Date(selectedSprint.start_date);
        const sprintEnd = new Date(selectedSprint.end_date);

        return start >= sprintStart && end <= sprintEnd;
      });

      setFilteredInsights(updatedFilteredInsights);
    }
  }, [selectedSprint, insights]);

  const LoadProjectDetails = async () => {
    if (project && token) {
      const projectDetails = await TeamfloAPI.getProjectDetails(token, project.id);

      const newInsights: any[] = [];
      projectDetails.insights.forEach((insight: any) => {
        newInsights.push(insight);
      });

      setInsights(newInsights);
      setFilteredInsights(newInsights);
      setLineChartData(newInsights);

      if (projectDetails.badges) {
        setBadges(projectDetails.badges);
      }

      if (newInsights.length > 0) {
        setToDate(newInsights[newInsights.length - 1].date_end);
        setFromDate(newInsights[newInsights.length - 1].date_start);
      } else {
        setToDate("");
        setFromDate("");
      }

      let newSprints = UpdateSprints(projectDetails.project.sprints, newInsights);
      newSprints = removeSprintsWithNoData(newSprints, newInsights);

      setSprints(newSprints);
      setSelectedSprint(newSprints[newSprints.length - 1]);

      setLoading(false);
    }
  };

  const UpdateSprints = (sprints: any[], insights: any[]) => {
    return sprints
      .map((sprint: any) => {
        const filtered = insights.filter((insight: any) => {
          const start = new Date(insight.date_start);
          const end = new Date(insight.date_end);
          const sprint_start = new Date(sprint.start_date);
          const sprint_end = new Date(sprint.end_date);

          return start >= sprint_start && end <= sprint_end;
        });

        const overal = InsightManager.GetLatest(filtered, "overal_individual");
        if (overal) {
          sprint.overal = overal?.data?.value;
        } else {
          const engagement = InsightManager.GetLatest(filtered, "engagement_individual");
          if (engagement) {
            sprint.overal = engagement?.data?.value;
          }
        }

        return sprint;
      })
      .filter((sprint: any) => {
        const filtered = insights.filter((insight: any) => {
          const start = new Date(insight.date_start);
          const end = new Date(insight.date_end);
          const sprint_start = new Date(sprint.start_date);
          const sprint_end = new Date(sprint.end_date);

          return start >= sprint_start && end <= sprint_end;
        });

        return filtered.length > 0;
      });
  };

  const removeSprintsWithNoData = (sprints: any[], insights: any[]) => {
    return sprints.filter((sprint: any) => {
      const filtered = insights.filter((insight: any) => {
        const start = new Date(insight.date_start);
        const end = new Date(insight.date_end);
        const sprint_start = new Date(sprint.start_date);
        const sprint_end = new Date(sprint.end_date);

        return start >= sprint_start && end <= sprint_end;
      });

      return filtered.length > 0;
    });
  };

  const GetData = async (token: string) => {
    const data = await TeamfloAPI.getUser(token);

    if (data) {
      setName(data.first_name);
      setProjects(data.projects);
      setProject(data.projects[0]);

      if (data.projects[0]?.badges) {
        setBadges(data.projects[0].badges);
      }

      return { token, ...data };
    } else {
      navigate("/");
    }
  };

  const ToggleFilter = (type: string) => {
    const newFilters = [...filters];
    if (filters.includes(type)) {
      setFilters(newFilters.filter((f: string) => f !== type));
    } else {
      setFilters([...newFilters, type]);
    }
  };

  const calculateOverall = (filters: string[], sentiment: number, caring: number, engagement: number): number => {
    const values = [];
    if (filters.length === 0 || filters.includes("sentiment")) values.push(sentiment);
    if (filters.length === 0 || filters.includes("caring")) values.push(caring);
    if (filters.length === 0 || filters.includes("engagement")) values.push(engagement);

    if (values.length === 0) return 0;
    const total = values.reduce((acc, val) => acc + val, 0);
    return Math.round(total / values.length);
  };

  const overalIndividual = calculateOverall(
    filters,
    Math.round(InsightManager.GetLatest(filteredInsights, "sentiment_individual")?.data?.value || 0),
    Math.round(InsightManager.GetLatest(filteredInsights, "caring_individual")?.data?.value || 0),
    Math.round(InsightManager.GetLatest(filteredInsights, "engagement_individual")?.data?.value || 0)
  );
  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="text-gray-500 font-bold text-xl">Loading data...</span>
      </div>
    );
  }

  return (
    <DashboardContext.Provider
      value={{
        filters,
        setFilters,
        fromDate,
        toDate,
        projects,
        setProject,
        project,
        showFilter,
        setShowFilter,
        selectedSprint,
        setSelectedSprint,
        sprints,
        badges,
        insights,
        setDates: (fromDate: string, toDate: string) => {
          setFromDate(fromDate);
          setToDate(toDate);
        },
        ToggleFilter,
        displayedSprints, 
        setDisplayedSprints,
      }}
    >
      <div
        className="flex flex-col mx-auto items-center"
        style={{
          maxWidth: 360,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "0px"
        }}
      >
        {!(isDetailPage || isMeVsTeamView) && <Header showBackground={true} />}
        <div style={{ flex: 1, width: "100%", paddingBottom: "60px" }}>
          {!(isDetailPage || isMeVsTeamView) && (
            <>
              {filteredInsights.length > 0 && (
                <div>
                  <SprintHistory
                    sprints={sprints}
                    onSelectSprint={(sprint) => {
                      setSelectedSprint(sprint);
                      setFilteredInsights(
                        insights.filter((insight: any) => {
                          const start = new Date(insight.date_start);
                          const end = new Date(insight.date_end);
                          const sprintStart = new Date(sprint.start_date);
                          const sprintEnd = new Date(sprint.end_date);

                          return start >= sprintStart && end <= sprintEnd;
                        })
                      );
                    }}
                  />

                  <div
                    className="bg-white rounded-xl mt-6 p-6 w-full max-w-md"
                    style={{ maxWidth: 360, paddingBottom: "24px", marginBottom: "0px" }}
                  >
                    <h4 className="font-bold text-dark mb-4" >
                      Your Performance
                    </h4>
                    <RingChart
                      sentiment={
                        InsightManager.GetLatest(
                          filteredInsights,
                          "sentiment_individual"
                        )?.data?.value || 0
                      }
                      caring={
                        InsightManager.GetLatest(
                          filteredInsights,
                          "caring_individual"
                        )?.data?.value || 0
                      }
                      engagement={
                        InsightManager.GetLatest(
                          filteredInsights,
                          "engagement_individual"
                        )?.data?.value || 0
                      }
                      overalIndividual={overalIndividual}
                      sentimentColor={sentiment_rgb}
                      caringColor={caring_rgb}
                      engagementColor={engagement_rgb}
                    />
                  </div>
                  <div
                    className="bg-white rounded-xl mt-6 p-6 w-full max-width-md"
                    style={{ maxWidth: 360, paddingBottom: "24px", marginBottom: "12px" , marginTop: "12px"}}
                  >
                    <LineChart
                      insights={lineChartData}
                      showTeamMetrics={false}
                    />
                  </div>
                </div>
              )}

              {(filters.length === 0 || filters.includes("engagement")) && (
                <Insight
                  title={"Engagement"}
                  color={engagement_rgb}
                  insight={InsightManager.GetLatest(
                    filteredInsights,
                    "engagement_individual"
                  )}
                />
              )}
              {(filters.length === 0 || filters.includes("caring")) && (
                <Insight
                  title={"Caring"}
                  color={caring_rgb}
                  insight={InsightManager.GetLatest(
                    filteredInsights,
                    "caring_individual"
                  )}
                />
              )}
              {(filters.length === 0 || filters.includes("sentiment")) && (
                <Insight
                  title={"Sentiment"}
                  color={sentiment_rgb}
                  insight={InsightManager.GetLatest(
                    filteredInsights,
                    "sentiment_individual"
                  )}
                />
              )}

              <Badges badges={badges} />

              {filteredInsights.length === 0 && (
                <div>
                  <p className="text-center text-gray-500 mt-8 text-xl">
                    No insights found for <br /> this sprint:{" "}
                    {selectedSprint?.name}
                  </p>
                  <button
                    className="bg-purple font-bold text-white rounded-xl px-8 py-2 mt-12"
                    onClick={() => setShowFilter(true)}
                  >
                    Look for more data
                  </button>
                </div>
              )}

              <TimeFilter sprints={sprints} />
              <NewBadgesPopup badges={badges} />
            </>
          )}

          <Outlet />
        </div>

        {!isDetailPage && !isMeVsTeamView && (
          <div
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              height: "58px",
              maxWidth: 360,
              backgroundColor: "#fff",
              zIndex: 1000,
            }}
          >
            <BottomNavigation
              projectName={project?.name || "Project"}
              projectCount={projects?.length || 1}
              selectedTab={isMeVsTeamView ? "Team" : "My stats"}
              onSelectTab={(tab) => {
                if (tab === "Team") {
                  navigate(`/dashboard/meVsTeam?token=${token}`);
                } else {
                  navigate(`/dashboard?token=${token}`);
                }
              }}
              lineChartDataOpt={insights}
            />
          </div>
        )}
      </div>
    </DashboardContext.Provider>
  );
  
};

export default DashboardHomePage;
