import React, { useContext } from "react";
import { DashboardContext } from "../context/DashboardContext";

interface RingProperties {
  color: string;
  progress: number;
  strokeWidth: number;
  size: number;
  scale?: number;
  filter?: string;
  isTeam?: boolean;
  showCenterValue?: boolean; 
}

const Ring: React.FC<RingProperties> = ({
  progress,
  strokeWidth,
  size,
  color,
  scale = 1,
  filter,
  isTeam = false,
  showCenterValue = false,
}) => {
  const { filters, setFilters } = useContext(DashboardContext);

  if (filter && filters.length > 0 && !filters.includes(filter)) {
    return null;
  }

  function onlyThisone() {
    return filter && filters.length === 1 && filters.includes(filter);
  }

  function scale_() {
    return onlyThisone() ? 1.4 : scale;
  }

  function strokeWidth_() {
    return onlyThisone() ? 3 : strokeWidth;
  }

  return (
    <>
      <svg
        onClick={() => {
          if (filter) {
            if (filters.length > 1) {
              setFilters([filter]);
            } else if (filters.includes(filter)) {
              setFilters([]);
            } else {
              setFilters([filter]);
            }
          }
        }}
        style={{
          cursor: "pointer",
          transform: `scale(${scale_()}) rotate(-90deg)`,
        }}
        className="ActivityRings"
        viewBox={`0 0 ${size} ${size}`}
      >
        <g className="ring">
          <circle
            className="background"
            style={{ stroke: `rgba(${color},0.1)` }}
            cx="50%"
            cy="50%"
            strokeWidth={strokeWidth_()}
            r="15.915"
            fill="none"
          />
          {progress > 0 && (
            <circle
              className="completed"
              style={{ stroke: `rgba(${color},1)` }}
              cx="50%"
              cy="50%"
              strokeWidth={strokeWidth_()}
              r="15.915"
              strokeDasharray={`${progress}, 100`}
              fill="none"
            />
          )}
        </g>
      </svg>
      {filters.length === 1 && onlyThisone() ? (
        <p className="big-number" style={{ color: `rgba(${color},1)` }}>
          <b className="font-bold">{progress}</b>%
        </p>
      ) : (
        filters.length === 1 &&
        showCenterValue && (
          <p className="text-xl absolute left-[50%] top-[50%] translate-x-[-40%] translate-y-[-50%] text-light-gray font-bold">
            {progress}%
          </p>
        )
      )}
    </>
  );
};

export default Ring;
