import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/components/BottomNavigation.module.css";
import groupOfThree from "../img/groupOfThree.svg";
import arrowUpGrey from "../img/arrow-up-grey.png"; 
import personMyStats from "../img/personMyStats.svg";

interface BottomNavigationProps {
  projectName: string;
  projectCount?: number;
  selectedTab?: "My stats" | "Team";
  onSelectTab: (tab: "My stats" | "Team") => void;
  lineChartDataOpt?: any[] | undefined;
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({
  projectName,
  projectCount = 1,
  selectedTab,
  onSelectTab,
  lineChartDataOpt = null,
}) => {

  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const navigate = useNavigate();

  const toggleProjectDropdown = () => {
    setIsProjectOpen((prev) => !prev);
  };

  const handleTeamClick = () => {
    onSelectTab("Team");
    navigate("/dashboard/meVsTeam", { state: { lineChartData: lineChartDataOpt } });
  };


  return (
    <div className={styles.container}>
      {projectCount > 1 ? (
        <button className={styles["project-button"]} onClick={toggleProjectDropdown}>
          <img
            src={arrowUpGrey}
            alt="Arrow Icon"
            className={`${styles["arrow-icon"]} ${isProjectOpen ? styles["arrow-icon-open"] : ""}`}
            width="10"
            height="10"
          />
          {projectName}
        </button>
      ) : (
        <span className={styles["project-name"]}>{String(projectName)}</span>
      )}

      <div className={styles["buttons-container"]}>
        <button
          className={`${styles["button"]} ${selectedTab === "My stats" ? styles["button-active"] : styles["button-inactive"]}`}
          onClick={() => onSelectTab("My stats")}
        >
          My stats
        </button>

        <div className={styles["icon"]}>
          <img
            src={selectedTab === "My stats" ? personMyStats : groupOfThree}
            alt={selectedTab === "My stats" ? "My Stats Icon" : "Team Icon"}
          />
        </div>

        <button
          className={`${styles["button"]} ${selectedTab === "Team" ? styles["button-active"] : styles["button-inactive"]}`}
          onClick={handleTeamClick}
        >
          Team
        </button>
      </div>
    </div>
  );
};

export default BottomNavigation;
