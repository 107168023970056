import React, { useContext, useState, useEffect } from "react";
import { DashboardContext } from "../context/DashboardContext";
import Ring from "./ring";
import { engagement_rgb } from "../config";

const filter_icon = require("../img/filter-icon.png");

interface SprintHistoryProps {
  sprints: any[];
  onSelectSprint: (sprint: any) => void;
}

const SprintHistory: React.FC<SprintHistoryProps> = ({ sprints, onSelectSprint }) => {
  const {
    setShowFilter,
    showFilter,
    selectedSprint,
    setSelectedSprint,
    setDates,
    setDisplayedSprints, 
  } = useContext(DashboardContext);

  const [localDisplayedSprints, setLocalDisplayedSprints] = useState<any[]>([]);
  const [animatingSprint, setAnimatingSprint] = useState<any | null>(null);

  useEffect(() => {
    if (sprints && sprints.length > 0 && selectedSprint) {
      const filteredSprints = sprints.filter(
        (sprint) =>
          sprint.overal !== undefined && new Date(sprint.end_date) <= new Date()
      );
  
      const selectedIndex = filteredSprints.findIndex((s) => s.id === selectedSprint.id);
  
      let startIndex = Math.max(0, selectedIndex - 2);
      let endIndex = Math.min(filteredSprints.length, startIndex + 5);
  
      if (endIndex - startIndex < 5) {
        if (startIndex === 0) {
          endIndex = Math.min(5, filteredSprints.length);
        } else if (endIndex === filteredSprints.length) {
          startIndex = Math.max(0, filteredSprints.length - 5);
        }
      }
  
      const visibleSprints = filteredSprints.slice(startIndex, endIndex);
      setLocalDisplayedSprints(visibleSprints);
      setDisplayedSprints(visibleSprints);
    }
  }, [sprints, selectedSprint, setDisplayedSprints]);
  

  const handleSprintClick = (sprint: any) => {
    setAnimatingSprint(sprint);
    setTimeout(() => {
      setSelectedSprint(sprint); 
      setShowFilter(false); 
      setDates(sprint.start_date, sprint.end_date); 
      onSelectSprint(sprint); 
      setAnimatingSprint(null); 
    }, 400);
  };

  return (
    <div
      className="flex flex-row justify-between my-4 transition-transform duration-300"
      style={{ maxWidth: 320, margin: "0 auto" }}
    >
      {localDisplayedSprints.map((sprint: any, index: number) => (
        <div
          key={index}
          onClick={() => handleSprintClick(sprint)}
          className={`w-[36px] h-[36px] relative cursor-pointer flex items-center justify-center ${
            animatingSprint === sprint ? "scale-110" : ""
          }`}
          style={{
            opacity: selectedSprint === sprint ? 1 : 0.25,
            zIndex: 50,
            transition: "transform 0.3s ease, opacity 0.3s ease",
          }}
        >
          <Ring
            color={engagement_rgb}
            progress={sprint.overal ? sprint.overal : 0}
            strokeWidth={4}
            size={40}
            scale={1.4}
          />
          {sprint.name && (
            <p className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-xs">
              {sprint.name.match(/\d+/) ? sprint.name.match(/\d+/)[0] : ""}
            </p>
          )}
        </div>
      ))}
      <button
        onClick={() => {
          setShowFilter(!showFilter);
        }}
        className="w-[36px] h-[36px] rounded-full bg-purple flex justify-center items-center"
      >
        <img src={filter_icon} className="w-[18px] h-auto" alt="Filter icon" />
      </button>
    </div>
  );
};

export default SprintHistory;
