import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header";
import { DashboardContext } from "../context/DashboardContext";

const backArrow = require("../img/back-arrow.png");

const BadgeDetailsPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location as { state: { badge: any; history?: any[] } };
    const badge = state?.badge;

    const { badges } = React.useContext(DashboardContext);

    const badgeHistory = badges.filter(b => b.badge_type === badge.badge_type);

    if (!badge) {
        navigate("/dashboard");
        return null;
    }

    const mapBadgeType = (badgeType: string): string => {
        const mapping: { [key: string]: string } = {
            caring_individual: "caring",
            sentiment_individual: "sent",
            engagement_individual: "eng",
        };
        return mapping[badgeType] || badgeType;
    };

    const getBadgeImageDirect = (badge: any): string => {
        const level = badge.description.includes("90%") ? "90" : "80";
        const badgeType = mapBadgeType(badge.badge_type);
        return require(`../img/badges/badge_${badgeType}_${level}.png`);
    };

    const relatedBadges = [
        { level: 80, label: "Score 80%" },
        { level: "80_2", label: "Score 80% - Level 2 Streak" },
        { level: 90, label: "Score 90%" },
        { level: "90_2", label: "Score 90% - Level 2 Streak" },
        { level: "long", label: `Longest Streak` },
    ];

    const getRelatedBadgeImage = (related: { level: number | string; label: string }): string => {
        const badgeType = mapBadgeType(badge.badge_type);
        return require(`../img/badges/badge_${badgeType}_${related.level}.png`);
    };

    return (
        <div className="badge-details-page-container flex flex-col mx-auto items-center" style={{ maxWidth: 360 }}>
            <div className="w-full px-4" style={{ marginBottom: "-16px", zIndex: 10, position: "relative", marginTop:"-12px" }}>
                <button 
                    onClick={() => navigate("/dashboard/mybadges")}
                    className="text-purple-500 text-lg flex items-center"
                    style={{ position: "relative", top: "90px" }}
                >
                    <img 
                        src={backArrow} 
                        alt="Back" 
                        style={{ width: "24.5px", height: "14px", marginRight: "10px", marginLeft:"10px"}}
                    /> 
                    Badges
                </button>
            </div>

            <Header showBackground={true} />

            <h2 className="font-bold front  text-left text-2xl mt-6 w-full px-6" style={{ color: "#0E0E2C", fontFamily: 'sans-serif',
                        fontWeight: 600, fontSize: '22px',lineHeight: '15.4px'}}>
                Details
            </h2>

            <div className="bg-white rounded-xl mt-6 p-4 w-full text-center">
                {/* Badge */}
                <img 
                    src={getBadgeImageDirect(badge)} 
                    alt={badge.name} 
                    style={{ width: "286.74px", height: "244.44px", margin: "0 auto" }} 
                />

                {/* Badge Name */}
                <h3 
                    className="font-bold mt-4" 
                    style={{
                        fontFamily: "sans-serif",
                        fontWeight: 500,
                        fontSize: "22px",
                        lineHeight: "22px",
                        color: "#0E0E2C",
                        textAlign: "center",
                    }}
                >
                    {badge.name}
                </h3>

                {/* Badge Description */}
                <p 
                    className="mt-2"
                    style={{
                        fontFamily: "sans-serif",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "22px",
                        color: "#0E0E2C",
                        textAlign: "center",
                        width: "284px", 
                        margin: "0 auto",
                    }}
                >
                    {badge.description}
                </p>
            </div>


            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <h4 className="font-bold text-left mb-4" style={{ color: "#492CBB" }}>History</h4>
                {badgeHistory.length > 0 ? (
                    badgeHistory.map((entry: any, index: number) => {
                        const date = new Date(entry.awarded_date);
                        const correctedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                        const formattedDate = correctedDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });

                        return (
                            <div key={index} className="flex items-center mb-4">
                                <img src={getBadgeImageDirect(entry)} alt={entry.name} className="w-25 h-20 mr-4" />
                                <div>
                                    <p className="text-dark font-medium">{entry.name}</p>
                                    <p className="text-gray-600 text-sm">{formattedDate}</p>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p className="text-center text-gray-600">No history available for this badge.</p>
                )}
            </div>

            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <h4 className="font-bold text-left mb-4" style={{ color: "#492CBB" }}>
                    What can you earn with {badge.name}
                </h4>
                <div className="flex flex-row flex-wrap justify-center">
                    {relatedBadges.map((related, index) => (
                        <div key={index} className="w-[30%] mr-[3%] mt-4">
                            <img
                                src={getRelatedBadgeImage(related)}
                                alt={`${related.label}`}
                                className="w-full"
                            />
                            <p className="text-center text-dark">{related.label}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );    
};

export default BadgeDetailsPage;
