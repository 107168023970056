import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import BottomNavigation from "../components/BottomNavigation";
import { DashboardContext } from "../context/DashboardContext";
import franjaNumMenuLeft from "../img/menu/franjaNumMenuLeft.svg";
import franjaNumMenuMiddle from "../img/menu/franjaNumMenuMiddle.svg";
import franjaNumMenuRight from "../img/menu/franjaNumMenuRight.svg";
import teamfloIcon from "../img/teamflo-icon.svg"; 
import styles from "../styles/layouts/Menu.module.css";

const Menu: React.FC = () => {
  const navigate = useNavigate();
  const { project } = useContext(DashboardContext); 
  const token = localStorage.getItem("token");

  const handleRedirectToDashboard = () => {
    if (token) {
      navigate(`/dashboard?token=${token}`);
    } else {
      console.error("No token disponible. Redirigiendo al inicio...");
      navigate("/");
    }
  };

  return (
    <div className={styles["menu-container"]}>
      {/* Header for the menu */}
      <div className={styles["menu-header"]}>
        {/* Title with logo */}
        <div className={styles["menu-logo-container"]}>
          <img
            src={teamfloIcon}
            alt="Teamflo logo"
            className={styles["menu-logo"]}
          />
        </div>
  
        {/* Three "X" replacing circles */}
        <div className={styles["menu-close-buttons"]}>
          <div
            className={styles["menu-close-button-purple"]}
            onClick={handleRedirectToDashboard}
          >
            ×
          </div>
          <div
            className={styles["menu-close-button-light"]}
            onClick={handleRedirectToDashboard}
          >
            ×
          </div>
          <div
            className={styles["menu-close-button-lighter"]}
            onClick={handleRedirectToDashboard}
          >
            ×
          </div>
        </div>
      </div>
  
      {/* Menu content */}
      <div className={styles["menu-content"]}>
        <div className={styles["menu-content-buttons"]}>
          <button
            className={styles["menu-content-button"]}
            onClick={() => navigate("/quick-guide")}
          >
            Quick Guide
          </button>
          <button
            className={styles["menu-content-button"]}
            onClick={() => navigate("/about-us")}
          >
            About Us
          </button>
          <button
            className={styles["menu-content-button"]}
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </button>
        </div>
      </div>
  
      {/* Decorative SVG Line */}
      <svg
        className={styles["menu-decorative-line"]}
        width="360"
        height="282"
        viewBox="0 0 360 282"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-197.882 232.173C-197.882 232.173 -123.306 330.3 -54.6177 233.717C14.0811 137.14 -4.48716 45.715 125.255 173.427C254.989 301.141 254.152 190.547 258.17 120.359C262.187 50.1704 266.468 -33.7038 400.16 25.0446"
          stroke="#A391E9"
          strokeWidth="10"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </svg>
  
      {/* Decorative numbers */}
      <img
        src={franjaNumMenuMiddle}
        alt="Decorative numbers middle"
        className={styles["menu-decorative-middle"]}
      />
      <img
        src={franjaNumMenuLeft}
        alt="Decorative numbers left"
        className={styles["menu-decorative-left"]}
      />
      <img
        src={franjaNumMenuRight}
        alt="Decorative numbers right"
        className={styles["menu-decorative-right"]}
      />
  
      {/* Bottom Navigation */}
      <div className={styles["bottom-navigation"]}>
        <div className={styles["bottom-navigation-inner"]}>
          <BottomNavigation
            projectName={project?.name || "No Project Selected"}
            projectCount={Array.isArray(project) ? project.length : 1}
            onSelectTab={(tab) => console.log(`Selected tab: ${tab}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default Menu;
