import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface TokenContextType {
  token: string | null;
  setToken: (token: string) => void;
}

const TokenContext = createContext<TokenContextType | undefined>(undefined);

export const TokenProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation(); 
  const navigate = useNavigate();
  const [token, setTokenState] = useState<string | null>(null);

  const setToken = (newToken: string) => {
    setTokenState(newToken);
    localStorage.setItem("token", newToken);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const urlToken = urlParams.get("token");
    const storedToken = localStorage.getItem("token");

    if (urlToken) {
      setToken(urlToken);
    } else if (storedToken) {
      setToken(storedToken);
    } else {
      navigate("/");
    }
  }, [location, navigate]);

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error("useToken debe usarse dentro de un TokenProvider");
  }
  return context;
};
