import React, { useContext, useEffect } from "react";
import Header from "../components/header";
import CounterBadges from "../components/counterBadges";
import { DashboardContext } from "../context/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";

const backArrow = require("../img/back-arrow.png");

type Badge = {
    badge_type: string;
    name: string;
    description: string;
    awarded_date: string;
    badge_level?: string;
    streak?: number;
    count?: number;
};
const MyBadgesPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { badges } = useContext(DashboardContext);

    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get("token");
    const tokenFromStorage = localStorage.getItem("token");
    const token = tokenFromUrl || tokenFromStorage;

    useEffect(() => {
        if (!token) {
            console.error("No token disponible. Redirigiendo al lobby...");
            navigate("/");
        } else if (!tokenFromUrl && tokenFromStorage) {
            navigate(`${location.pathname}?token=${token}`, { replace: true });
        }
    }, [token, tokenFromUrl, tokenFromStorage, navigate, location.pathname]);

    const groupBadges = (badges: Badge[]): Badge[] => {
        const grouped = badges.reduce((acc: { [key: string]: Badge }, badge: Badge) => {
            const level = badge.badge_level === "gold" ? "90" : "80";
            const suffix = badge.name.toLowerCase().includes("frequency") ? "_2" : "";
            const key = `${badge.badge_type}_${level}${suffix}`;

            if (!acc[key]) {
                acc[key] = { ...badge, count: 1 };
            } else {
                acc[key].count = (acc[key].count || 1) + 1;
            }
            return acc;
        }, {});

        return Object.values(grouped);
    };

    const scoreBadges = groupBadges(
        badges.filter(
            (badge) =>
                ["caring_individual", "sentiment_individual", "engagement_individual"].includes(badge.badge_type) &&
                !badge.name.toLowerCase().includes("frequency")
        )
    );

    const frequencyBadges = groupBadges(
        badges.filter((badge) => badge.name.toLowerCase().includes("frequency"))
    );

    const allCategoriesBadges: Badge[] = groupBadges(
        badges.filter((badge) => badge.badge_type === "all_rounder")
    );

    const handleBadgeClick = (badge: Badge) => {
        const badgeHistory = badges.filter((b) => b.badge_type === badge.badge_type);
        navigate(`/dashboard/badgedetails?token=${token}`, {
            state: { badge, history: badgeHistory },
        });
    };

    const mapBadgeType = (badgeType: string): string => {
        const mapping = {
            caring_individual: "caring",
            sentiment_individual: "sent",
            engagement_individual: "eng",
        };
        return mapping.hasOwnProperty(badgeType) ? mapping[badgeType as keyof typeof mapping] : badgeType;
    };

    const getBadgeImage = (badge: Badge) => {
        const badgeType = mapBadgeType(badge.badge_type);
        const suffix = badge.name.toLowerCase().includes("frequency") ? "_2" : "";
        const level = badge.badge_level === "gold" ? "90" : "80";

        const key = `badge_${badgeType}_${level}${suffix}`;

        try {
            return require(`../img/badges/${key}.png`);
        } catch (error) {
            console.error("Error loading image for badge:", key);
            return require("../img/badges/sentiment_empty.png");
        }
    };

    if (!token) {
        return null;
    }
    
    return (
        <div className="my-badges-page-container flex flex-col mx-auto items-center" style={{ maxWidth: 328 }}>
            <Header showBackground={true} />

            <div className="w-full px-4 mb-4" style={{ marginBottom: "-10px" }}>
                <button
                    onClick={() => navigate("/dashboard")}
                    className="flex items-center text-[#492CBB] gap-2"
                >
                    <img src={backArrow} alt="Back" className="w-8 h-4" />
                    <span className="text-[#492CBB] font-semibold text-[16px] leading-[28px]">
                        Home
                    </span>
                </button>
            </div>

            <CounterBadges
                totalBadges={badges.length}
                engagementCount={badges.filter(
                    (badge) => badge.badge_type === "engagement_individual"
                ).length}
                caringCount={badges.filter(
                    (badge) => badge.badge_type === "caring_individual"
                ).length}
                sentimentCount={badges.filter(
                    (badge) => badge.badge_type === "sentiment_individual"
                ).length}
            />

            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[16px] leading-[18px] text-[#53565A]">
                        Score ({scoreBadges.reduce((total, badge) => total + (badge.count || 1), 0)})
                    </h4>
                    <span
                        className="text-[#492CBB] font-semibold text-[14px] leading-[15.4px] underline cursor-pointer text-right"
                        onClick={() => navigate("/dashboard/allbadges")}
                    >
                        Check all
                    </span>
                </div>

                <div className="flex flex-row flex-wrap">
                    {scoreBadges.map((badge, index) => (
                        <div
                            key={index}
                            className="w-[30%] mr-[3%] mt-4 relative flex flex-col items-center"
                            style={{ cursor: "default" }} 
                        >
                            <img className="w-full" src={getBadgeImage(badge)} alt={badge.name} />
                            <p className="text-center text-dark mt-2">{badge.name}</p>
                            <p className="text-center text-gray-500 text-sm">{badge.description}</p>

                            {badge.count && (
                                <span className="badge-score-counter">{badge.count}</span>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[16px] leading-[18px] text-[#53565A]">
                        Frequency ({frequencyBadges.reduce((total, badge) => total + (badge.count || 1), 0)})
                    </h4>
                    <span
                        className="text-[#492CBB] font-semibold text-[14px] leading-[15.4px] underline cursor-pointer text-right"
                        onClick={() => navigate("/dashboard/allbadges")}
                    >
                        Check all
                    </span>
                </div>

                <div className="flex flex-row flex-wrap">
                    {frequencyBadges.map((badge, index) => (
                        <div
                            key={index}
                            className="w-[30%] mr-[3%] mt-4 relative flex flex-col items-center"
                        >
                            <img className="w-full" src={getBadgeImage(badge)} alt={badge.name} />
                            <p className="text-center text-dark mt-2">{badge.name}</p>
                            <p className="text-center text-gray-500 text-sm">{badge.description}</p>

                            {badge.count && (
                                <span className="badge-score-counter">{badge.count}</span>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="font-semibold text-[16px] leading-[18px] text-[#53565A]">
                        All Categories ({allCategoriesBadges.length})
                    </h4>
                    <span
                        className="text-[#492CBB] font-semibold text-[14px] leading-[15.4px] underline cursor-pointer text-right"
                        onClick={() => navigate("/dashboard/allbadges")}
                    >
                        Check all
                    </span>
                </div>
                <div className="flex flex-row flex-wrap">
                    {allCategoriesBadges.map((badge, index) => (
                        <div
                            key={index}
                            className="w-[30%] mr-[3%] mt-4 relative flex flex-col items-center"
                        >
                            <img
                                className="w-full"
                                src={require("../img/badges/badge_all_80.png")}
                                alt={badge.name}
                            />
                            <p className="text-center text-dark mt-2">{badge.name}</p>
                            <p className="text-center text-gray-500 text-sm">{badge.description}</p>

                            {badge.count && (
                                <span className="badge-score-counter">{badge.count}</span>
                            )}
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default MyBadgesPage;
