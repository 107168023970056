import React from "react";

interface InsightTeamProps {
  insights: {
    icon : string; 
    message: string;
    color: string; 
    type: string;
  }[];
  title: string; 
  filters: string[]; 
}

const InsightTeam: React.FC<InsightTeamProps> = ({ insights, title, filters }) => {
  const filteredInsights = insights.filter((insight) =>
    filters.length === 0 || filters.includes(insight.type)
  );

  return (
    <div
      className="bg-white rounded-xl mt-4 p-4"
      style={{ maxWidth: 360 }}
    >
      <h4
        className="font-semibold text-gray-600 text-sm mb-4 uppercase"
        style={{
          fontFamily: "Graphik, sans-serif",
          fontSize: "14px",
          lineHeight: "16px",
          color: "#92929D",
        }}
      >
        {title}
      </h4>

      {/* Insights */}
      <ul className="space-y-4">
        {filteredInsights.map((insight, index) => (
          <li
            key={index}
            className="flex items-start space-x-3 pb-3"
            style={{
              borderBottom: `1px dashed ${insight.color}`,
              paddingBottom: "12px",
            }}
          >
            <div
              className="flex items-center justify-center"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "10px"
              }}
            >
              <img
                src={insight.icon}
                alt=""
                style={{
                  width: "24px", 
                  height: "24px",
                  marginRight: "18px", 
                  objectFit: "contain",
                }}
              />
            </div>
            <p
              className="text-gray-600 text-sm"
              style={{
                fontFamily: "Graphik, sans-serif",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#53565A",
              }}
            >
              {insight.message}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InsightTeam;