import React, { useContext, useEffect, useState } from "react";
import Fuse from "fuse.js";
import styles from "../styles/components/TimeFilter.module.css";
import { DashboardContext } from "../context/DashboardContext";

const check_icon = require("../img/check.png");
const search_icon = require("../img/search-icon.png");

interface TimeFilterProps {
  sprints: any[];
}

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  return `${month} ${day}`;
}

function formatSprintTime(sprint: any) {
  return <p>{`(${formatDate(sprint.start_date)} / ${formatDate(sprint.end_date)})`}</p>;
}

const TimeFilter: React.FC<TimeFilterProps> = ({ sprints }) => {
  const {
    setDates,
    projects,
    project,
    setProject,
    showFilter,
    setShowFilter,
    selectedSprint,
    setSelectedSprint,
  } = useContext(DashboardContext);

  console.log("Selected sprint on Time Filter: ", selectedSprint);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredSprints, setFilteredSprints] = useState<any[]>([]);

  const getValidSprints = (sprints: any[]) => {
    return sprints.filter(
      (sprint) =>
        sprint.overal !== undefined && new Date(sprint.end_date) <= new Date()
    );
  };

  useEffect(() => {
    const validSprints = getValidSprints(sprints);
    setFilteredSprints(validSprints);
  }, [sprints]);

  useEffect(() => {
    if (!selectedSprint && filteredSprints.length > 0) {
      const lastSprint = filteredSprints[filteredSprints.length - 1]; 
      setSelectedSprint(lastSprint); 
      setDates(lastSprint.start_date, lastSprint.end_date); 
    }
  }, [selectedSprint, filteredSprints]);
  

  const fuse = new Fuse(getValidSprints(sprints), {
    keys: ["name"],
    threshold: 0.3,
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query) {
      const result = fuse.search(query).map(({ item }) => item);
      setFilteredSprints(result);
    } else {
      setFilteredSprints(getValidSprints(sprints));
    }
  };

  const ClearFilters = () => {
    setSearchTerm("");
    const validSprints = getValidSprints(sprints);
    setFilteredSprints(validSprints);
    setSelectedSprint(null);
    setShowFilter(false);
    setDates("", "");
  };

  return (
    <div>
      {showFilter && <div className={styles.overlay}></div>}

      {showFilter && (
        <div className={styles["time-filter-container"]}>
          <div className={`${styles["project-name"]}`}>
            <p className={`${styles["project-title"]}`}>{project?.name || "No Project Selected"}</p>
            <div className={styles["underline"]}></div>
          </div>

          <div
            className="pb-4 pt-4 mb-2"
            style={{ borderBottom: "2px solid #EBEBEB" }}
          >
            <div className={styles["search-bar"]}>
              <img src={search_icon} className={styles["search-icon"]} alt="search icon" />
              <input
                className={styles["search-input"]}
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          </div>

          <div className="px-6">
            {[...filteredSprints].reverse().map((sprint, index) => (
              <div
                key={index}
                className={styles["sprint-item"]}
                onClick={() => {
                  setSelectedSprint(sprint);
                  setShowFilter(false);
                  setDates(sprint.start_date, sprint.end_date);
                }}
              >
                <div className={styles["sprint-info"]}>
                  <div className={styles["sprint-info-icon"]}>
                    {selectedSprint && selectedSprint.name === sprint.name && (
                      <img src={check_icon} alt="check mark" />
                    )}
                  </div>
                  <b>{sprint.name}</b>
                </div>
                {formatSprintTime(sprint)}
              </div>
            ))}

            {filteredSprints.length === 0 && (
              <p className={styles["no-sprints"]}>No sprints found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeFilter;
