import React, { useContext } from "react";
import Header from "../components/header";
import { DashboardContext } from "../context/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";

import badge_caring_80 from "../img/badges/badge_caring_80.png";
import badge_caring_90 from "../img/badges/badge_caring_90.png";
import badge_caring_80_2 from "../img/badges/badge_caring_80_2.png";
import badge_caring_90_2 from "../img/badges/badge_caring_90_2.png";

import badge_sent_80 from "../img/badges/badge_sent_80.png";
import badge_sent_90 from "../img/badges/badge_sent_90.png";
import badge_sent_80_2 from "../img/badges/badge_sent_80_2.png";
import badge_sent_90_2 from "../img/badges/badge_sent_90_2.png";

import badge_eng_80 from "../img/badges/badge_eng_80.png";
import badge_eng_90 from "../img/badges/badge_eng_90.png";
import badge_eng_80_2 from "../img/badges/badge_eng_80_2.png";
import badge_eng_90_2 from "../img/badges/badge_eng_90_2.png";

import badge_all_80 from "../img/badges/badge_all_80.png";
import badge_all_90 from "../img/badges/badge_all_90.png";

import badge_empty from "../img/badges/sentiment_empty.png";
const backArrow = require("../img/back-arrow.png");

const badgeImages: { [key: string]: string } = {
    "caring_80": badge_caring_80,
    "caring_90": badge_caring_90,
    "caring_80_2": badge_caring_80_2,
    "caring_90_2": badge_caring_90_2,

    "sentiment_80": badge_sent_80,
    "sentiment_90": badge_sent_90,
    "sentiment_80_2": badge_sent_80_2,
    "sentiment_90_2": badge_sent_90_2,

    "engagement_80": badge_eng_80,
    "engagement_90": badge_eng_90,
    "engagement_80_2": badge_eng_80_2,
    "engagement_90_2": badge_eng_90_2,

    "all_rounder_80": badge_all_80,
    "all_rounder_90": badge_all_90,
};

const AchievementsPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get("token");
    const tokenFromStorage = localStorage.getItem("token");
    const token = tokenFromUrl || tokenFromStorage;

    React.useEffect(() => {
        if (!token) {
            console.error("No token disponible. Redirigiendo al lobby...");
            navigate("/");
        } else if (!tokenFromUrl && tokenFromStorage) {
            navigate(`${location.pathname}?token=${token}`, { replace: true });
        }
    }, [token, tokenFromUrl, tokenFromStorage, navigate, location.pathname]);

    const { badges } = useContext(DashboardContext);

    const groupBadges = (badges: any[], filter: "score" | "frequency") => {
        const filteredBadges = badges.filter((badge) => {
            const isFrequency = badge.name?.toLowerCase().includes("frequency");

            if (filter === "score") return badge.name && !isFrequency;
            if (filter === "frequency") return badge.name && isFrequency;
            return false;
        });

        const grouped = filteredBadges.reduce((acc: { [key: string]: any }, badge) => {
            const level = badge.badge_level === "gold" ? "90" : "80";

            const cleanedType = badge.badge_type.replace(/_individual/, "");
            const suffix = filter === "frequency" ? "_2" : "";
            const key = `${cleanedType}_${level}${suffix}`;

            if (!acc[key]) acc[key] = { ...badge, count: 0 };
            acc[key].count += 1;
            return acc;
        }, {});

        return grouped;
    };

    const groupedBadgesScore = groupBadges(badges, "score");
    const groupedBadgesFrequency = groupBadges(badges, "frequency");

    const getBadgeImage = (badgeType: string, level: string, frequency = false) => {
        const badgeTypeShort = badgeType.replace(/(_individual|_frequency)/, "");
        const suffix = frequency ? "_2" : "";
        const key = `${badgeTypeShort}_${level}${suffix}`;
        return badgeImages[key] || badge_empty;
    };

    const handleBadgeClick = (badge: any) => {
        navigate(`/dashboard/badgedetails?token=${token}`, { state: { badge } });
    };

    const renderBadgeSection = (title: string, badgeTypes: string[], groupedBadges: any, frequency = false) => {
        const levels = ["80", "90"];

        return (
            <div className="bg-white rounded-xl mt-6 p-4 w-full">
                <h4 className="font-bold mb-4 text-left" style={{ color: "#53565A" }}>{title}</h4>
                <div className="flex flex-row flex-wrap">
                    {badgeTypes.map(badgeType =>
                        levels.map(level => {
                            const suffix = frequency ? "_2" : "";
                            const key = `${badgeType}_${level}${suffix}`;
                            const earnedBadge = groupedBadges[key];
                            const count = earnedBadge ? earnedBadge.count : 0;

                            //make the first letter of BadeType uppercase
                            const badgeTitle = badgeType.charAt(0).toUpperCase() + badgeType.replace(/_/g, " ").slice(1);

                            return (
                                <div
                                    key={key}
                                    onClick={earnedBadge ? () => handleBadgeClick(earnedBadge) : undefined}
                                    className={`w-[30%] mr-[3%] mt-4 relative flex flex-col items-center ${
                                        earnedBadge ? "cursor-pointer" : "cursor-default"
                                    }`}
                                >
                                    <img
                                        className={`w-full ${!earnedBadge ? "grayscale opacity-50" : ""}`}
                                        src={getBadgeImage(badgeType, level, frequency)}
                                        alt={badgeType}
                                    />
                                    <p className="text-center">{badgeTitle}</p>
                                    <p className="text-center text-gray-500 text-sm">
                                        {frequency ? "2 sprints in a row" : "above"} {level}%
                                    </p>
                                    {earnedBadge && count > 1 && (
                                        <span className="badge-score-counter-achievements">{count}</span>
                                    )}
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        );
    };

    if (!token) {
        return null;
    }

    return (
        <div className="achievements-page-container flex flex-col mx-auto items-center" style={{ maxWidth: 360 }}>
            <div className="w-full px-4" style={{ marginBottom: "-16px", zIndex: 10, position: "relative", marginTop: "-12px" }}>
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center text-[#492CBB] font-medium text-[16px] leading-[28px]"
                    style={{ position: "relative", top: "90px" }}
                >
                    <img src={backArrow} alt="Back" style={{ width: "24.5px", height: "14px", marginRight: "10px", marginLeft: "10px" }} />
                    <span style={{ color: "#492CBB", fontWeight: "500", fontSize: "16px", lineHeight: "28px" }}>Badges</span>
                </button>
            </div>

            <Header showBackground={true} />
            <h2 className="font-bold text-left text-2xl mt-6 w-full px-6" style={{ color: "#0E0E2C", fontSize: "22px", fontWeight: "500", lineHeight: "22px", marginTop: "28px" }}>
                Check all
            </h2>

            {renderBadgeSection("Score", ["caring", "sentiment", "engagement"], groupedBadgesScore)}
            {renderBadgeSection("Frequency", ["caring", "engagement", "sentiment"], groupedBadgesFrequency, true)}

            {renderBadgeSection("All Categories", ["all_rounder"], groupedBadgesScore)}
        </div>
    );
};

export default AchievementsPage;
