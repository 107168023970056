import { createContext } from "react";

interface DashboardContextProps {
    filters: string[];
    setFilters: (filters: string[]) => void;
    fromDate: string;
    toDate: string;
    setDates: (fromDate: string, toDate: string) => void;
    project: any;
    projects: any[];
    setProject: (project: any) => void;
    showFilter: boolean;
    setShowFilter: (showFilter: boolean) => void;
    selectedSprint: any;
    setSelectedSprint: (sprint: any) => void;
    sprints: any[];
    badges: any[];
    insights: any[];
    ToggleFilter: (type: string) => void;
    displayedSprints: any[]; 
    setDisplayedSprints: (sprints: any[]) => void; 
}

export const DashboardContext = createContext<DashboardContextProps>({
    filters: [],
    setFilters: () => {},
    fromDate: "",
    toDate: "",
    setDates: () => {},
    project: {},
    projects: [],
    setProject: () => {},
    showFilter: false,
    setShowFilter: () => {},
    selectedSprint: {},
    setSelectedSprint: () => {},
    sprints: [],
    badges: [],
    insights: [],
    ToggleFilter: () => {},
    displayedSprints: [], 
    setDisplayedSprints: () => {}, 
});
