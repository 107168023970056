interface Sprint {
    start_date: string;
    end_date: string;
    name: string;
  }
  
  export const mapSprintDates = (dates: string[], sprints: Sprint[]): (string | number)[] => {
    if (!sprints || !Array.isArray(sprints)) {
      console.warn("sprints no está definido o no es un array");
      return dates;
    }
  
    return dates.map((currentDate) => {

      if (currentDate === "01-01") {

        const lastSprint = sprints.find((sprint) => {
          const sprintEnd = sprint.end_date.slice(5); // mm-dd
          return sprintEnd === "01-01";
        });
  
        const lastSprintNumber = lastSprint?.name ? lastSprint.name.match(/\d+/)?.[0] : null;
  
        return lastSprintNumber ? Number(lastSprintNumber)  : currentDate;
      }

      const sprint = sprints.find((sprint) => {
        const sprintStart = sprint.start_date.slice(5); // mm-dd
        const sprintEnd = sprint.end_date.slice(5); // mm-dd
        return currentDate >= sprintStart && currentDate <= sprintEnd;
      });
  
      const sprintNumber = sprint?.name ? sprint.name.match(/\d+/)?.[0] : null;
      return sprintNumber ? Number(sprintNumber) : currentDate;
    });
  };
  