import React, { useContext } from "react";
import { DashboardContext } from "../context/DashboardContext";
import Ring from "./ring";

interface RingChartProps {
  sentiment: number;
  caring: number;
  engagement: number;
  overalIndividual: number;
  sentimentColor: string;
  caringColor: string;
  engagementColor: string;
}

const RingChart: React.FC<RingChartProps> = ({
  sentiment,
  caring,
  engagement,
  overalIndividual,
  sentimentColor,
  caringColor,
  engagementColor,
}) => {
  const { filters, setFilters } = useContext(DashboardContext);

  const isFilterActive = (type: string) =>
    filters.length === 0 || filters.includes(type);

  const centerValue = (() => {
    const activeValues = [];
    if (filters.includes("sentiment")) activeValues.push(sentiment);
    if (filters.includes("caring")) activeValues.push(caring);
    if (filters.includes("engagement")) activeValues.push(engagement);

    if (activeValues.length > 0) {
      return Math.round(
        activeValues.reduce((acc, value) => acc + value, 0) / activeValues.length
      );
    }
    return overalIndividual; 
  })();

  const toggleFilter = (type: string) => {
    if (filters.includes(type)) {
      setFilters(filters.filter((f) => f !== type));
    } else {
      setFilters([...filters, type]);
    }
  };

  return (
    <div className="flex flex-row mt-8">
      <div className="RingContainer w-[132px] h-[132px] mb-4 relative">
        {isFilterActive("sentiment") && (
          <Ring
            color={sentimentColor}
            progress={Math.round(sentiment)} 
            strokeWidth={2.8}
            size={40}
            scale={1.43}
            filter="sentiment"
            showCenterValue={filters.includes("sentiment")}
          />
        )}

        {isFilterActive("caring") && (
          <Ring
            color={caringColor}
            progress={Math.round(caring)} 
            strokeWidth={3.5}
            size={40}
            scale={1.1}
            filter="caring"
            showCenterValue={filters.includes("caring")}
          />
        )}

        {isFilterActive("engagement") && (
          <Ring
            color={engagementColor}
            progress={Math.round(engagement)} 
            strokeWidth={5}
            size={40}
            scale={0.77}
            filter="engagement"
            showCenterValue={filters.includes("engagement")}
          />
        )}
        {(filters.length === 0 || filters.length === 3 || filters.length === 2) && (
          <p className="text-xl absolute left-[50%] top-[50%] translate-x-[-40%] translate-y-[-50%] text-light-gray font-bold">
            {centerValue}%
          </p>
        )}

      </div>

      <div className="flex-1 ml-8 flex flex-col justify-center">
        <div
          onClick={() => toggleFilter("engagement")}
          style={{
            opacity: isFilterActive("engagement") ? 1 : 0.25,
          }}
          className="bg-light-purple w-full mb-1 rounded-lg p-2 flex flex-row cursor-pointer"
        >
          <div className="w-[24px] mr-2">
            <Ring
              color={engagementColor}
              progress={engagement}
              strokeWidth={9}
              size={40}
              scale={1}
            />
          </div>
          <p
            style={{
              fontWeight: filters.includes("engagement") ? "bold" : "regular",
            }}
          >
            Engagement
          </p>
        </div>

        <div
          onClick={() => toggleFilter("caring")}
          style={{
            opacity: isFilterActive("caring") ? 1 : 0.25,
          }}
          className="bg-light-purple w-full mb-1 rounded-lg p-2 flex flex-row cursor-pointer"
        >
          <div className="w-[24px] mr-2">
            <Ring
              color={caringColor}
              progress={caring}
              strokeWidth={9}
              size={40}
              scale={1}
            />
          </div>
          <p
            style={{
              fontWeight: filters.includes("caring") ? "bold" : "regular",
            }}
          >
            Caring
          </p>
        </div>

        <div
          onClick={() => toggleFilter("sentiment")}
          style={{
            opacity: isFilterActive("sentiment") ? 1 : 0.25,
          }}
          className="bg-light-purple w-full mb-1 rounded-lg p-2 flex flex-row cursor-pointer"
        >
          <div className="w-[24px] mr-2">
            <Ring
              color={sentimentColor}
              progress={sentiment}
              strokeWidth={9}
              size={40}
              scale={1}
            />
          </div>
          <p
            style={{
              fontWeight: filters.includes("sentiment") ? "bold" : "regular",
            }}
          >
            Sentiment
          </p>
        </div>
      </div>
    </div>
  );
};

export default RingChart;
