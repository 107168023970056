import { useState, useEffect, useContext } from "react";
import { DashboardContext } from "../context/DashboardContext";
import { engagement_rgb, sentiment_rgb, caring_rgb } from "../config";
import Line from "./Line";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import InsightManager from "../manager/insightManager";
import styles from "../styles/components/LineChart.module.css"; 
import { mapSprintDates } from "./mapSprintDates";


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const options: any = {
  scales: {
    y: {
      min: 0,
      max: 100,
      beginAtZero: true,
      ticks: {
        callback: (value: number) => `${value}%`,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.4,
      borderWidth: 4,
    },
    point: {
      radius: 1,
      borderWidth: 1,
    },
  },
};

const MakeDataSet = (values: number[], label: string, color: string, isTeam: boolean = false): any => {
  return {
    label: label,
    data: values,
    fill: false,
    borderColor: color,
    tension: 0.1,
    borderDash: isTeam ? [5, 5] : undefined,
    pointRadius: isTeam ? 5 : 1,
    pointBorderWidth: isTeam ? 2 : 1,
  };
};

const fillNulls = (array: (number | null)[]): number[] => {
  for (let i = 1; i < array.length; i++) {
    if (array[i] === null) {
      array[i] = array[i - 1];
    }
  }
  for (let i = array.length - 2; i >= 0; i--) {
    if (array[i] === null) {
      array[i] = array[i + 1];
    }
  }
  return array as number[];
};

interface LineChartProps {
  insights: any;
  showTeamMetrics?: boolean;
}

const LineChart: React.FC<LineChartProps> = ({ insights, showTeamMetrics = true }) => {
  const { filters, setFilters, displayedSprints } = useContext(DashboardContext);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (insights && insights.length > 0 && displayedSprints && displayedSprints.length > 0) {
      SetupData();
    }
  }, [filters, insights, displayedSprints]); 
  
  const GetMetrics = (insights: any[], type: string): any => {
    return InsightManager.GetLabelsAndValues(insights, type,1000) || {};
  };

  const SetupData = () => {
    if (!displayedSprints || displayedSprints.length === 0) 
      return;
    
    const labels = displayedSprints.map((sprint) => {
      const date = new Date(sprint.end_date);
      date.setDate(date.getDate() + 1); 
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${month}-${day}`;
    });
    
    const sprintLabels = mapSprintDates(labels, displayedSprints);
    const engagement_metrics = GetMetrics(insights, "engagement_individual");
    const caring_metrics = GetMetrics(insights, "caring_individual");
    const sentiment_metrics = GetMetrics(insights, "sentiment_individual");


    const engagement_metrics_team = showTeamMetrics
      ? GetMetrics(insights, "engagement_team")
      : {};
    const caring_metrics_team = showTeamMetrics
      ? GetMetrics(insights, "caring_team")
      : {};
    const sentiment_metrics_team = showTeamMetrics
      ? GetMetrics(insights, "sentiment_team")
      : {};
  
    const datasets = [];
    if (filters.length === 0 || filters.includes("engagement")) {
      const engagementData = labels.map((label) =>
        engagement_metrics[label] !== undefined ? engagement_metrics[label] : null
      );
      fillNulls(engagementData);
      datasets.push(MakeDataSet(engagementData, "Engagement Individual", `rgba(${engagement_rgb}, 1)`));
  
      if (showTeamMetrics) {
        const engagementDataTeam = labels.map((label) =>
          engagement_metrics_team[label] !== undefined ? engagement_metrics_team[label] : null
        );
        fillNulls(engagementDataTeam);
        datasets.push(
          MakeDataSet(engagementDataTeam, "Engagement Team", `rgba(${engagement_rgb}, 0.6)`, true)
        );
      }
    }
  
    if (filters.length === 0 || filters.includes("caring")) {
      const caringData = labels.map((label) =>
        caring_metrics[label] !== undefined ? caring_metrics[label] : null
      );
      fillNulls(caringData);
      datasets.push(MakeDataSet(caringData, "Caring Individual", `rgba(${caring_rgb}, 1)`));
  
      if (showTeamMetrics) {
        const caringDataTeam = labels.map((label) =>
          caring_metrics_team[label] !== undefined ? caring_metrics_team[label] : null
        );
        fillNulls(caringDataTeam);
        datasets.push(
          MakeDataSet(caringDataTeam, "Caring Team", `rgba(${caring_rgb}, 0.6)`, true)
        );
      }
    }
  
    if (filters.length === 0 || filters.includes("sentiment")) {
      const sentimentData = labels.map((label) =>
        sentiment_metrics[label] !== undefined ? sentiment_metrics[label] : null
      );
      fillNulls(sentimentData);
      datasets.push(
        MakeDataSet(sentimentData, "Sentiment Individual", `rgba(${sentiment_rgb}, 1)`)
      );
  
      if (showTeamMetrics) {
        const sentimentDataTeam = labels.map((label) =>
          sentiment_metrics_team[label] !== undefined ? sentiment_metrics_team[label] : null
        );
        fillNulls(sentimentDataTeam);
        datasets.push(
          MakeDataSet(sentimentDataTeam, "Sentiment Team", `rgba(${sentiment_rgb}, 0.6)`, true)
        );
      }
    }
  
    setData({
      labels: sprintLabels, 
      datasets,
    });
  };
  
  const ToggleFilter = (type: string) => {
    if (filters.includes(type)) {
      setFilters(filters.filter((f: string) => f !== type));
    } else {
      setFilters([...filters, type]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4 className={styles.headerTitle}>
          {showTeamMetrics ? "Performance" : "Sprint performance over time"}
        </h4>
        {showTeamMetrics && (
          <div className="flex items-center space-x-4">
            <div className={styles.colorSolid} />
            <div className={styles.colorDashed} />
          </div>
        )}
      </div>

      <div className="flex justify-around mb-4">
        {(["engagement", "caring", "sentiment"] as const).map((metric) => (
          <button
            key={metric}
            onClick={() => ToggleFilter(metric)}
            className={`${styles.metricButton} ${
              filters.includes(metric) || filters.length === 0
                ? styles[`metric${metric.charAt(0).toUpperCase() + metric.slice(1)}`]
                : styles.metricInactive
            }`}
          >
            {metric.charAt(0).toUpperCase() + metric.slice(1)}
          </button>
        ))}
      </div>

      {data ? (
        <Line
          chartName="SPRINT"
          width={320}
          height={200}
          data={data}
          options={options}
        />
      ) : (
        <div className="flex justify-center items-center py-10">
          <span className="text-gray-500 font-medium">No data available for chart.</span>
        </div>
      )}
    </div>
  );
};

export default LineChart;
