import { useContext, useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { DashboardContext } from "../context/DashboardContext";
import badge_icons from "../constants/badges_img";
import { createPortal } from "react-dom";
import { BadgesProps } from "../types";

const sentiment_empty = require("../img/badges/sentiment_empty.png");
const engagement_empty = require("../img/badges/engagement_empty.png");
const caring_empty = require("../img/badges/caring_empty.png");

const Badges: React.FC<BadgesProps> = ({ badges }) => {
    const { fromDate, toDate } = useContext(DashboardContext);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token"); 
    const [recentBadges, setRecentBadges] = useState<any[]>([]);
    const [detailBadge, setDetailBadge] = useState<any>(null);

    function GetBadgeImage(props: any): any {
        var s = props.badge.streak - 1;
        var style = "w-[224px] mx-auto relative";
        if (s > 2) {
            style = "w-[324px] mx-auto relative";
        }
        if (s > 4) {
            s = 4;
        }
        
        var src = badge_icons["engagement_individual"][0];
        if (props.badge.badge_type === 'all_rounder') {
            src = props.badge.badge_level === "gold"
                ? badge_icons[props.badge.badge_type][1]
                : badge_icons[props.badge.badge_type][0];
        } else {
            src = badge_icons[props.badge.badge_type][s];
        }

        return (<img className={style} src={src} alt="" />);
    }

    useEffect(() => {
        const sortedBadges = badges
            .sort((a, b) => new Date(b.awarded_date).getTime() - new Date(a.awarded_date).getTime())
            .slice(0, 3);
        
        setRecentBadges(sortedBadges);
    }, [badges]);

    const handleBadgeClick = (badge: any) => {
        const badgeHistory = badges.filter(b => b.badge_type === badge.badge_type);
        
        const newPath = `/dashboard/badgedetails${token ? `?token=${token}` : ''}`; 
        navigate(newPath, {
            state: { badge, history: badgeHistory }
        });
    };
    
    if (recentBadges.length === 0) {
        return (
            <div
                className="w-full rounded-xl bg-white"
                style={{ margin: '8px', padding: '24px', maxWidth: 360, minHeight: 80 }}

            >
                <div className="flex justify-between items-center">
                    <h4 className="font-semibold text-[#53565A] text-[14px] leading-[15.4px] -ml-2">
                        Achievements over time ({badges.length})
                    </h4>
                    <Link
                        to="/dashboard/allbadges"
                        className="underline"
                        style={{
                            fontFamily: 'Graphik, sans-serif',
                            fontWeight: 600,
                            fontSize: '14px',
                            lineHeight: '15.4px',
                            color: '#492CBB',
                            textAlign: 'right',
                            paddingRight: '10px'
                        }}
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                        Check all
                    </Link>
                </div>
        
                <div className="flex flex-row justify-between mt-6 space-x-3">
                    <div className="flex flex-col items-center w-[83px]">
                        <img src={engagement_empty} alt="Engagement" style={{ width: '83px', height: '73px', opacity: '0.6' }} />
                        <p className="text-center text-gray-400 font-medium text-[14px] leading-[16px]">Engagement</p>
                    </div>
        
                    <div className="flex flex-col items-center w-[83px]">
                        <img src={caring_empty} alt="Caring" style={{ width: '83px', height: '73px', opacity: '0.6' }} />
                        <p className="text-center text-gray-400 font-medium text-[14px] leading-[16px]">Caring</p>
                    </div>
        
                    <div className="flex flex-col items-center w-[83px]">
                        <img src={sentiment_empty} alt="Sentiment" style={{ width: '83px', height: '73px', opacity: '0.6' }} />
                        <p className="text-center text-gray-400 font-medium text-[14px] leading-[16px]">Sentiment</p>
                    </div>
                </div>
        
                <p className="font-semibold text-center text-black text-[16px] leading-[16px] mt-6">
                    No badges yet!
                </p>
                <p className="text-center text-[#53565A] font-normal text-[15px] leading-[20px] mt-2">
                    Keep engaging with your team and completing tasks to earn your first badge!
                </p>
            </div>
        );
    }

    return (
        <div className="w-full rounded-xl bg-white mt-6 p-6" style={{ maxWidth: 360, minHeight: 80 }}>
            <div className="flex justify-between items-center mb-4">
                <h4
                    className="font-bold text-dark"
                    style={{
                        fontFamily: 'Graphik, sans-serif',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '15.4px',
                        color: '#53565A',
                    }}
                >
                    Achievements over time ({badges.length})
                </h4>

                <Link
                    to="/dashboard/mybadges"
                    className="underline"
                    style={{
                        fontFamily: 'Graphik, sans-serif',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '15.4px',
                        color: '#492CBB',
                        textAlign: 'right',
                    }}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    Check all
                </Link>
            </div>

            <div className="flex flex-row flex-wrap justify-start mt-4">
                {recentBadges.map((badge, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center w-[30%] cursor-pointer"
                        onClick={() => handleBadgeClick(badge)}
                    >
                        <div className="flex items-center justify-center" style={{ width: '83px', height: '73px' }}>
                            <GetBadgeImage badge={badge} />
                        </div>
                        <p
                            className="text-center"
                            style={{
                                fontFamily: 'Graphik, sans-serif',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '16px',
                                color: '#53565A',
                                marginTop: '8px',
                            }}
                        >
                            {badge.name}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Badges;
