import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { DashboardContext } from "../context/DashboardContext"; 
import headerBG from "../img/header-bg.png";
import logo from "../img/teamflo-icon-purple.png"; 
import teamfloIcon from "../img/teamflo-icon.svg"; 

interface HeaderProps {
  backButton?: boolean;
  onBack?: () => void;
  showBackground?: boolean; 
}

const Header: React.FC<HeaderProps> = ({ backButton, onBack, showBackground = true }) => {
  const token = localStorage.getItem("token");

  //console.log("Token recibido en Header:", token);
  const { project } = useContext(DashboardContext); 
  const [menuOpen, setMenuOpen] = useState(false); 
  const navigate = useNavigate(); 

  const handleMenuNavigation = () => {
    // console.log("Navigating to menu...");
    navigate("/dashboard/menu", { state: { token } }); 
  };

  return (
    <div className="mx-auto mb-4 relative" style={{ maxWidth: "100%", zIndex: 20 }}>

      {showBackground && (
        <img
          src={headerBG}
          alt="decorative graphic"
          className="w-full"
          style={{ marginBottom: "-40px" }}
        />
      )}

      {/* Encabezado */}
      <div
        className="relative flex items-center justify-between px-6"
        style={{
          width: "320px",
          height: 50,
          margin: "0 auto",
          marginTop: showBackground ? -70 : 0,
          marginBottom: "8px",
          backgroundColor: "#C9C5E8",
          opacity: 0.993,
          borderRadius: "12px",
        }}
      >

        {backButton && (
          <button
            onClick={onBack}
            className="text-[#492CBB] text-lg font-semibold"
            style={{ marginRight: 8 }}
          >
            ← Back
          </button>
        )}

        <div className="flex items-center" style={{ margin: 0, padding: 0 }}>
          <img
            src={teamfloIcon}
            alt="Teamflo logo"
            style={{
              width: 100, 
              height: "auto",
            }}
          />
        </div>

        {/* Tres puntos */}
        <div
          className="flex flex-row items-center space-x-2 cursor-pointer"
          onClick={handleMenuNavigation} 
        >
          <div
            className="dot"
            style={{
              background: "#872B93",
              width: "8px",
              height: "8px",
              borderRadius: "50%",
            }}
          ></div>
          <div
            className="dot"
            style={{
              background: "#AE70B6",
              width: "8px",
              height: "8px",
              borderRadius: "50%",
            }}
          ></div>
          <div
            className="dot"
            style={{
              background: "#C99FCE",
              width: "8px",
              height: "8px",
              borderRadius: "50%",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
