import React, { useState, useEffect, useContext, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { createPortal } from "react-dom";
import * as rive from "@rive-app/canvas";

import { TeamfloAPI } from "../manager/apimanager";
import { DashboardContext } from "../context/DashboardContext";
import { BadgesProps, Badge } from "../types";

function GetBadgeAnimation({ badge }: { badge: Badge }): JSX.Element {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const riveInstance = useRef<rive.Rive | null>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const animationMap: Record<string, string> = {
                caring_individual: "caring.riv",
                engagement_individual: "engagement.riv",
                sentiment_individual: "sentiment.riv",
                all_rounder: "allcat.riv",
            };

            const animationName =
                animationMap[badge.badge_type] || "allcat.riv";

            riveInstance.current = new rive.Rive({
                src: require(`../img/animations/${animationName}`),
                canvas: canvasRef.current,
                autoplay: true,
            });
        }

        return () => {
            riveInstance.current?.cleanup();
        };
    }, [badge.badge_type]);

    return (
        <canvas
            ref={canvasRef}
            width={300}
            height={300}
            style={{
                width: "70%",
                height: "auto",
            }}
        />
    );
}

const NewBadgesPopup: React.FC<BadgesProps> = ({ badges }) => {
    const { fromDate, toDate } = useContext(DashboardContext);
    const [searchParams] = useSearchParams();

    const [show, setShow] = useState(true); 
    const [isVisible, setIsVisible] = useState(false); 
    const [lastBadges, setLastBadges] = useState<Badge[]>([]);
    const slideContainerRef = useRef<HTMLDivElement>(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    useEffect(() => {
        const filteredBadges = badges.filter((badge) => {
            const awardedDate = new Date(badge.awarded_date);
            return (
                (!fromDate || awardedDate >= new Date(fromDate)) &&
                (!toDate || awardedDate <= new Date(toDate))
            );
        });

        const uniqueBadges = Array.from(
            new Map(filteredBadges.map((badge) => [badge.id, badge])).values()
        );

        setLastBadges(uniqueBadges);
        // console.log("Filtered Badges for the Latest Sprint:");
        // console.table(uniqueBadges);
    }, [badges, fromDate, toDate]);

    useEffect(() => {
        const hasSeenPopup = localStorage.getItem("hasSeenPopup");
        if (!hasSeenPopup) {
            const timeout = setTimeout(() => {
                setIsVisible(true); 
                localStorage.setItem("hasSeenPopup", "true"); 
            }, 3000);
    
            return () => clearTimeout(timeout); 
        } else {
            setShow(false); 
        }
    }, []);
    

    const dismiss = () => {
        if (searchParams.has("token")) {
            const token = searchParams.get("token") as string;
            const ids = lastBadges.map((badge) => badge.id);
            TeamfloAPI.markBadgeAsSeen(token, ids);
        }
        setShow(false);
    };

    const handleIndicatorClick = (index: number) => {
        if (slideContainerRef.current) {
            const slideWidth = slideContainerRef.current.offsetWidth;
            slideContainerRef.current.scrollTo({
                left: index * slideWidth,
                behavior: "smooth",
            });
            setCurrentSlide(index);
        }
    };

    const handleScroll = () => {
        if (slideContainerRef.current) {
            const slideWidth = slideContainerRef.current.offsetWidth;
            const newSlide = Math.round(
                slideContainerRef.current.scrollLeft / slideWidth
            );
            setCurrentSlide(newSlide);
        }
    };

    return createPortal(
        show && lastBadges.length > 0 ? (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                    opacity: isVisible ? 1 : 0, 
                    visibility: isVisible ? "visible" : "hidden", 
                    transition: "opacity 0.5s ease-in-out", 
                }}
            >
                <div
                    style={{
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                        width: "90%",
                        maxWidth: "360px",
                        textAlign: "center",
                        overflow: "hidden",
                        padding: "1em 0",
                    }}
                >
                    <h4
                    style={{
                        fontFamily: "Graphik, sans-serif",
                        fontWeight: 500,
                        fontSize: "22px",
                        lineHeight: "28px",
                        color: "#0E0E2C",
                        marginBottom: "1em",
                    }}
                >
                    You are<br />leading the charge
                </h4>
                    <div
                        ref={slideContainerRef}
                        onScroll={handleScroll}
                        style={{
                            display: "flex",
                            overflow: "hidden",
                            scrollBehavior: "smooth",
                        }}
                    >
                        {lastBadges.map((badge, index) => (
                            <div
                                key={index}
                                style={{
                                    flex: "0 0 100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <GetBadgeAnimation badge={badge} />
                                <p
                                    style={{
                                        fontFamily: "Graphik, sans-serif",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        lineHeight: "22px",
                                        color: "#0E0E2C",
                                        margin: "1em 0",
                                    }}
                                >
                                    {badge.description}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div style={{ marginTop: "0.5em" }}>
                        {lastBadges.map((_, index) => (
                            <span
                                key={index}
                                onClick={() => handleIndicatorClick(index)}
                                style={{
                                    display: "inline-block",
                                    width: "10px",
                                    height: "10px",
                                    margin: "0 5px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                        index === currentSlide
                                            ? "#6A5ACD"
                                            : "#D3D3D3",
                                    cursor: "pointer",
                                }}
                            ></span>
                        ))}
                    </div>
                    <button
                        onClick={dismiss}
                        style={{
                            margin: "1em",
                            padding: "0.5em 2em",
                            backgroundColor: "#492CBB",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            width: "280px"
                        }}
                    >
                        Ok
                    </button>
                </div>
            </div>
        ) : null,
        document.body
    );
};

export default NewBadgesPopup;
